<template>
  <div class="home" >
    <el-breadcrumb separator="">
        <el-breadcrumb-item>汇率中心</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="main-body" style="display: flex;justify-content: space-between;height: calc(100% - 110px)">
      <div style="width: 625px;height: 100%">
        <div class="ranking">
          <div class="ranking-title">
              最新汇率
          </div>
          <div  class="ranking-info ranking-center" v-if="newLoading">
            <i class="el-icon-loading"></i>
          </div>
          <div v-else class="ranking-info">
          <div class="ranking-content">
            <div class="ranking-header">
                本周签约使用汇率
            </div>
            <!--     有数据 本周签约使用汇率      -->
            <div v-if="signed_week">
                <div class="ranking-text">
                    <div class="time">
                        <div class="title">
                            生效日期
                        </div>
                        <div class="info">{{dateFormat(signed_week.sxrq)}}0点至{{dateFormat(signed_week.dqrq)}}24点</div>
                    </div>
                    <div class="rate">
                      <div class="rate-info">
                        <div class="rate-title">澳币汇率</div>
                        <div style="margin-top: 10px">{{signed_week.ab?signed_week.ab:'-'}} </div>
                      </div>
                      <div class="rate-info">
                        <div class="rate-title">美元汇率</div>
                        <div style="margin-top: 10px">{{signed_week.my?signed_week.my:'-'}} </div>
                      </div>
                      <div class="rate-info">
                        <div class="rate-title">加币汇率</div>
                        <div style="margin-top: 10px">{{signed_week.jb?signed_week.jb:'-'}} </div>
                      </div>
                      <div class="rate-info">
                        <div class="rate-title">新币汇率</div>
                        <div style="margin-top: 10px">{{signed_week.xb?signed_week.xb:'-'}} </div>
                      </div>
                      <div class="rate-info">
                        <div class="rate-title">港币汇率</div>
                        <div style="margin-top: 10px">{{signed_week.gb?signed_week.gb:'-'}} </div>
                      </div>
                    </div>
                    <div class="ramark">
                        <div class="title">
                            备注
                        </div>
                      <div class="info">{{signed_week.bz?signed_week.bz:'-'}}</div>
                    </div>
                </div>
            </div>
            <!--      无数据 本周签约使用汇率     -->
            <div v-else class="table-empty">
                <img src="../../static/empty.png" alt="" width="200">
                <div class="empty-text">此时间段内汇率数据未设置，请联系集团财务获取！</div>
            </div>
          </div>
          <div class="ranking-content">
            <div class="ranking-header">
                    本周核算业绩使用汇率
            </div>
            <!--     有数据 本周签约使用汇率      -->
            <div v-if="week">
                <div class="ranking-text">
                    <div class="time">
                        <div class="title">
                            生效日期
                        </div>
                        <div class="info">{{dateFormat(week.sxrq)}}0点至{{dateFormat(week.dqrq)}}24点</div>
                    </div>
                    <div class="rate">
                      <div class="rate-info">
                        <div class="rate-title">澳币汇率</div>
                        <div style="margin-top: 10px">{{week.ab?week.ab:'-'}} </div>
                      </div>
                      <div class="rate-info">
                        <div class="rate-title">美元汇率</div>
                        <div style="margin-top: 10px">{{week.my?week.my:'-'}} </div>
                      </div>
                      <div class="rate-info">
                        <div class="rate-title">加币汇率</div>
                        <div style="margin-top: 10px">{{week.jb?week.jb:'-'}} </div>
                      </div>
                      <div class="rate-info">
                        <div class="rate-title">新币汇率</div>
                        <div style="margin-top: 10px">{{week.xb?week.xb:'-'}} </div>
                      </div>
                      <div class="rate-info">
                        <div class="rate-title">港币汇率</div>
                        <div style="margin-top: 10px">{{week.gb?week.gb:'-'}} </div>
                      </div>
                    </div>
                    <div class="ramark">
                        <div class="title">
                            备注
                        </div>
                      <div class="info">{{week.bz?week.bz:'-'}}</div>
                    </div>
                </div>
            </div>
                <!--      无数据 本周签约使用汇率     -->
            <div v-else class="table-empty">
              <img src="../../static/empty.png" alt="" width="200">
              <div class="empty-text">此时间段内汇率数据未设置，请联系集团财务获取！</div>
            </div>
          </div>
          <div class="ranking-content">
            <div class="ranking-header">
                本季度核算提成使用汇率
            </div>
            <!--     有数据 本周签约使用汇率      -->
            <div v-if="quarter">
                <div class="ranking-text">
                    <div class="time">
                        <div class="title">
                            生效日期
                        </div>
                        <div class="info">{{dateFormat(quarter.sxrq)}}0点至{{dateFormat(quarter.dqrq)}}24点</div>
                    </div>
                  <div class="rate">
                    <div class="rate-info">
                      <div class="rate-title">澳币汇率</div>
                      <div style="margin-top: 10px">{{quarter.ab?quarter.ab:'-'}} </div>
                    </div>
                    <div class="rate-info">
                      <div class="rate-title">美元汇率</div>
                      <div style="margin-top: 10px">{{quarter.my?quarter.my:'-'}} </div>
                    </div>
                    <div class="rate-info">
                      <div class="rate-title">加币汇率</div>
                      <div style="margin-top: 10px">{{quarter.jb?quarter.jb:'-'}} </div>
                    </div>
                    <div class="rate-info">
                      <div class="rate-title">新币汇率</div>
                      <div style="margin-top: 10px">{{quarter.xb?quarter.xb:'-'}} </div>
                    </div>
                    <div class="rate-info">
                      <div class="rate-title">港币汇率</div>
                      <div style="margin-top: 10px">{{quarter.gb?quarter.gb:'-'}} </div>
                    </div>
                  </div>
                    <div class="ramark">
                        <div class="title">
                            备注
                        </div>
                      <div class="info">{{quarter.bz?quarter.bz:'-'}}</div>
                    </div>
                </div>
            </div>
            <!--      无数据 本周签约使用汇率     -->
            <div v-else class="table-empty">
              <img src="../../static/empty.png" alt="" width="200">
              <div class="empty-text">此时间段内汇率数据未设置，请联系集团财务获取！</div>
            </div>
          </div>
          </div>
        </div>
      </div>
      <div style="width: calc(100% - 625px)">
          <div class="ranking_r">
              <div class="ranking-title">
                  历史汇率
              </div>
              <div class="detail-top" ref="top">
                  <el-form class="moduleForm" :inline="true" :model="searchData">
                      <el-form-item label="数据类型" class="module-select">
                          <el-select
                                  v-model="searchData.type"
                                  filterable
                                  style="margin-left: 20px;"
                                  placeholder="选择数据类型"
                                  clearable>
                              <el-option
                                      v-for="item in dataType"
                                      :key="item.id"
                                      :label="item.title"
                                      :value="item.id">
                              </el-option>
                          </el-select>
                      </el-form-item>
                      <el-form-item label="生效日期" class="moduleForm-time">
                          <el-date-picker
                                  unlink-panels
                                  v-model="searchData.time"
                                  value-format="yyyy-MM-dd"
                                  type="daterange"
                                  range-separator="至"
                                  start-placeholder="选择开始日期"
                                  end-placeholder="选择结束日期">
                          </el-date-picker>
                      </el-form-item>
                  </el-form>
                  <div class="search-box">
                      <el-button class="search-btn" type="primary" icon="el-icon-search" :loading="searchLoad" @click="search">
                          搜索
                      </el-button>
                      <el-button type="text" class="clean-box" @click="clearFilter">
                          <img width="14" src="../../static/clean.svg" alt="">清除条件
                      </el-button>
                  </div>
              </div>
              <div  class="ranking-center" style="height: calc(100% - 256px);" v-if="loading">
                  <i class="el-icon-loading"></i>
              </div>
              <div v-else style="height: calc(100% - 256px);width: 100%">
              <div style="height: 100%;width: 100%" v-if="exchangeRate.length>0">
              <div class="history-info" ref="info">
                      <div class="history-content" ref="contents" v-for="item in exchangeRate">
                          <div class="ranking-header">
                            {{dataType.findIndex(i=>i.id===item.sjlx)!==-1?dataType.find(i=>i.id===item.sjlx).title:''}}
                          </div>
                          <!--     有数据 本周签约使用汇率      -->
                          <div class="ranking-text">
                            <div class="time">
                              <div class="title">
                                生效日期
                              </div>
                              <div class="info">{{dateFormat(item.sxrq)}}0点至{{dateFormat(item.dqrq)}}24点</div>
                            </div>
                              <div class="rate">
                                <div class="rate-info">
                                  <div class="rate-title">澳币汇率</div>
                                  <div style="margin-top: 10px">{{ item.ab?item.ab:'-' }}</div>
                                </div>
                                <div class="rate-info">
                                  <div class="rate-title">美元汇率</div>
                                  <div style="margin-top: 10px">{{ item.my?item.my:'-' }}</div>
                                </div>
                                <div class="rate-info">
                                  <div class="rate-title">加币汇率</div>
                                  <div style="margin-top: 10px">{{ item.jb?item.jb:'-' }}</div>
                                </div>
                                <div class="rate-info">
                                  <div class="rate-title">新币汇率</div>
                                  <div style="margin-top: 10px">{{ item.xb?item.xb:'-' }}</div>
                                </div>
                                <div class="rate-info">
                                  <div class="rate-title">港币汇率</div>
                                  <div style="margin-top: 10px">{{ item.gb?item.gb:'-' }}</div>
                                </div>
                              </div>
                              <div class="ramark">
                                  <div class="title">
                                      备注
                                  </div>
                                  <div class="info">{{ item.bz?item.bz:'-' }}</div>
                              </div>
                          </div>
                      </div>
              </div>
                  <div class="block">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="currentPage"
                        :page-sizes="[10, 50, 100, 300]"
                        :page-size="limit"
                        layout="sizes, prev, pager, next, jumper"
                        :total="totals">
                    </el-pagination>
              </div>
              </div>
              <div v-else class="history-empty">
                      <img src="../../static/empty.png" alt="" width="200">
                      <div class="empty-text">{{emtry}}</div>
              </div>
          </div>
          </div>
      </div>
    </div>
</div>
</template>

<script>
// @ is an alias to /src


import {GetDataType, GetExchangeList, GetHistoryeList} from "../api/index";
import {isInterceptorCode} from "../common/commonFuncs";
import moment from "moment";
export default {
  name: 'HomeView',
  components: {},
  data() {
        return {
            searchData: {
                type:'',
                time:''
            },
            searchLoad: false,
            newLoading: false,
            dataType:[],//数据类型下拉数据
            signed_week: '',
            week: '',
            quarter: '',
            exchangeRate: [],
            currentPage: 1,
            totals: 100,
            limit: 10,
            loading:false,
            emtry:'暂无数据，请先选择数据类型'
        }
    },
  created() {
    this.getNewGoodslist()//获取最新汇率
    this.getNewExchangeData()//获取历史汇率
    this.getDataType()//获取数据类型下拉
  },
  methods: {
    handleSizeChange(val) {
      this.limit = val
      this.currentPage=1
      // 刷新历史汇率列表
      this.getNewExchangeData()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      // 刷新列表
      this.getNewExchangeData()
    },
    // 历史汇率列表
    getNewExchangeData(e) {
      let para = {
        pageIndex: this.currentPage,
        pageSize: this.limit,
        sjlx:this.searchData.type,
        sxrq:this.searchData.time?this.searchData.time[0]:'',
        dqrq:this.searchData.time?this.searchData.time[1]:'',
      }
      this.loading=true
      GetHistoryeList(para).then(data => {
        if (data.code === 200) {
            this.loading=false
            if(e==='search'){
              this.searchLoad=false
              this.emtry='暂无数据'
              this.$message.success('搜索成功')
            }
            this.exchangeRate=data.data.data
            this.totals=data.data.total
        } else {
          if (!isInterceptorCode(data.code)) {
            this.loading=false
              if(e==='search'){
                  this.searchLoad=false
              }
              this.$message.error(data.msg)
          }
        }
      }).catch(() => {
        this.loading=false
        if(e==='search'){
            this.searchLoad=false
        }
        this.$message.error('服务器连接失败!')
      })
    },
    // 获取数据类型列表
    getDataType(){
          GetDataType().then(data=>{
              if (data.code === 200) {
                  let newArray = [];

              // 遍历原始对象
                  for (let key in data.data) {
                      if (data.data.hasOwnProperty(key)) {
                          // 获取键对应的值
                          let value = data.data[key];
                          // 构建新的对象
                          let newObj = { id: key, title: value };
                          // 将新对象添加到数组中
                          newArray.push(newObj);
                      }
                  }
                  this.dataType=newArray
              } else {
                  if (!isInterceptorCode(data.code)) {
                      this.$message.error(data.msg)
                  }
              }
          }).catch(() => {
              this.$message.error('服务器连接失败!')
          })
      },
    // 获取最新
    getNewGoodslist() {
        this.newLoading=true
      GetExchangeList().then(data => {
        if (data.code === 200) {
          this.signed_week=data.data[0]
          this.week=data.data[1]
          this.quarter=data.data[2]
          this.newLoading=false
        } else {
          if (!isInterceptorCode(data.code)) {
            this.newLoading=false
            this.$message.error(data.msg)
          }
        }
      }).catch(() => {
        this.newLoading=false
        this.$message.error('服务器连接失败!')
      })
    },
    // 搜索历史汇率
    search(){
      this.searchLoad=true
      this.currentPage=1
      this.getNewExchangeData('search')
    },
    clearFilter(){
      this.searchData.type=''
      this.searchData.time=''
    },
    dateFormat(date){
      return moment(date).format('YYYY年MM月DD日')
    },
  },

}
</script>
<style scoped>
 .home{
     box-sizing: border-box;
     background: #F5F5FD;
     width: 100%;
     height: 100vh;
     padding: 20px;
 }
 .ranking {
     width: 100%;
     padding-left: 20px;
     box-sizing: border-box;
     height: 100%;
     display: inline-block;
     background: #FFFFFF;
     border-right: 1px solid #ECEEF5 ;
 }
 .ranking-info::-webkit-scrollbar {
     width: 8px;
     height: 8px;
 }
 .ranking-info::-webkit-scrollbar-thumb {
     background-color: #ddd;
     border-radius: 3px;
 }
 .ranking-info::-webkit-scrollbar-track{
     background: #F9F9F9;
     border: 1px solid #ECEEF5;
 }
 .ranking_r {
     width: 100%;
     height: 100%;
     padding-left: 30px;
     box-sizing: border-box;
     max-height: 100%;
     display: inline-block;
     background: #FFFFFF;
 }
 .ranking-title{
     font-weight: 600;
     font-size: 20px;
     color: #606266;
     line-height: 28px;
     text-align: left;
 }
 .ranking-content{
     width: 570px;
     min-height: 325px;
     background: #FFFFFF;
     border-radius: 6px;
     border: 1px solid #EAEAEA;
     margin-bottom: 20px;
 }
 .ranking-header{
     width:100%;
     height: 50px;
     background: #F5F5FC;
     border-radius: 6px 6px 0px 0px;
     border-bottom: 1px solid #EAEAEA;
     font-weight: 600;
     font-size: 14px;
     color: #606266;
     line-height: 20px;
     padding: 15px 20px;
     box-sizing: border-box;
 }
 .ranking-info{
     margin-top: 20px;
     height:calc(100% - 48px);
     width: 100%;
     overflow-y: auto;
 }

 .table-empty{
     width: 100%!important;
     display: flex;
     align-items: center;
     justify-content: center;
     flex-direction: column;
     height: 295px;
 }
 .history-empty{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
 }
 .table-empty,.history-empty img{
     width: 200px;
 }
 .empty-text{
     font-size: 14px;
     font-weight: 400;
     color: #606266;
     width: 230px;
     line-height: 20px;
     text-align: center;
 }
 /* ---- S 表单筛选项 ---- */
 .moduleForm {
     display: flex;
     flex-wrap: wrap;
 }

 .moduleForm >>> .el-form-item {
     height: 40px;
     background: #FFFFFF;
     border-radius: 4px;
     border: 1px solid #DCDFE6;
     overflow: hidden;
     box-sizing: border-box;
     margin: 0 20px 20px 0;
     flex-shrink: 0;
 }

 .moduleForm >>> .el-form-item .el-form-item__label {
     padding: 0 0 0 15px;
     font-size: 14px;
     font-weight: 600;
     color: #606266;
 }

 .moduleForm >>> .el-form-item .el-input__inner {
     border: none;
 }

 .moduleForm >>> .el-form-item .el-form-item__content {
     flex: 1;
 }

 .moduleForm >>> .moduleForm-time {
     display: flex;
     padding-right: 15px;
     width: 562px;
     min-width: 400px;
     max-width: 100%;
 }

 .moduleForm >>> .moduleForm-time .el-date-editor.el-range-editor.el-input__inner.el-date-editor--daterange {
     position: relative;
     padding: 0;
     align-items: center;
     width: 100%;
 }

 .moduleForm >>> .moduleForm-time .el-range-separator {
     line-height: 38px;
     width: unset;
     padding: 0;
 }

 .moduleForm >>> .moduleForm-time .el-icon-time:before {
     content: "\E78E";
 }

 .moduleForm >>> .moduleForm-time .el-range__icon {
     position: absolute;
     right: 0;
     line-height: 38px;
     height: 38px;
 }

 .moduleForm >>> .moduleForm-time .el-range__close-icon {
     line-height: 38px;
     height: 38px;
     position: absolute;
     right: 35px;
 }

 .moduleForm >>> .moduleForm-time .el-range-input {
     text-align: left;
     margin-left: 20px;
     flex-shrink: 0;
 }

 .moduleForm >>> .module-select {
     width: 271px;
     box-sizing: border-box;
     display: flex;
 }

 .moduleForm >>> .module-select .el-select {
     margin-left: 0 !important;
     width: 100%;
 }

 .moduleForm >>> .module-select .el-select__tags {
     flex-wrap: nowrap;
 }

 .moduleForm >>> .module-select .el-input__inner {
     padding: 0 30px 0 20px;
 }

 .moduleForm >>> .module-select .el-select__caret {
     height: 38px;
     line-height: 38px;
 }
 .search-btn {
     height: 40px;
     background: #419EFF;
     border-radius: 4px;
     border: none;
     padding: 0 15px;
     font-size: 14px;
     font-weight: 400;
     color: #FFFFFF;
 }

 .search-btn >>> [class*=el-icon-] + span {
     margin-left: 10px;
 }

 .clean-box {
     margin-left: 20px!important;
 }

 .clean-box img {
     margin-right: 10px;
 }
 .detail-top{
     padding: 20px 0;
     border-bottom: 1px solid #F5F5FC;
     margin-right: 30px;
 }
 .history-info {
     margin-top: 20px;
     height: 100%;
     width: 100%;
     overflow-y: auto;
     display: flex;
     flex-wrap: wrap;
 }
 .history-content {
     width: calc(50% - 31px);
     /*min-height: 325px;*/
     /*height: auto;*/
     background: #FFFFFF;
     border-radius: 6px;
     border: 1px solid #EAEAEA;
     margin-bottom: 20px;
     margin-right: 31px;
     /*align-self: flex-start; !* 子元素水平对齐 *!*/
 }

 .history-info::-webkit-scrollbar {
     width: 8px;
     height: 8px;
 }
 .history-info::-webkit-scrollbar-thumb {
     background-color: #ddd;
     border-radius: 3px;
 }
 .history-info::-webkit-scrollbar-track{
     background: #F9F9F9;
     border: 1px solid #ECEEF5;
 }

 .history-content:nth-child(even) {
     margin-right: 0; /* 最后一个盒子不设置右侧间距 */
 }
 .ranking-text{
     height: calc(100% - 50px);
     padding: 30px;
     box-sizing: border-box;
 }
 .block {
     padding: 20px 0px 0;
 }

 .block .el-pagination {
     margin: 0;
     padding: 0;

 }

 .block >>> .el-pagination span:not([class*=suffix]) {
     height: 36px;
     line-height: 36px;
 }

 .block >>> .el-pagination .el-select .el-input {
     margin: 0;
 }

 .block >>> .el-pagination .el-input__inner {
     height: 36px;
 }

 .block >>> .el-pagination button {
     height: 36px;
 }

 .block >>> .el-pagination .el-pager li {
     height: 36px;
     line-height: 36px;
 }
 .rate{
     display: flex;
     font-size: 14px;
     flex-wrap: wrap;
     /*justify-content: space-between;*/
 }
 .rate-info{
     margin-right: 40px;
     flex-shrink: 0;
     margin-bottom: 25px;
 }
 .rate-title{
     font-weight: 600;
     color: #606266;
     line-height: 20px;
 }
 .ramark .title{
     height: 20px;
     margin-bottom: 10px;
     font-weight: 600;
     font-size: 14px;
     color: #606266;
     line-height: 20px;
 }
 .ramark .info{
     font-weight: 400;
     font-size: 14px;
     color: #606266;
     line-height: 25px;
     word-break: break-word;
 }
 .time{
     margin-bottom: 25px;
 }
 .time .title{
     height: 20px;
     margin-bottom: 10px;
     font-weight: 600;
     font-size: 14px;
     color: #606266;
     line-height: 20px;
 }
 .time .info{
     font-weight: 400;
     font-size: 14px;
     color: #606266;
     line-height: 25px;
 }
 @media screen and (max-width: 1597px){
     .history-info{
         height:calc(100% - 76px);
     }
 }
 @media screen and (max-width: 1280px){
     .history-content{
         margin-right: 0;
         min-height: 325px;
         /*min-width: 560px;*/
         min-width: calc(100% - 20px);
     }
 }
 @media screen and (max-width: 1568px){
     .history-content{
         min-height: 325px;
         margin-right: 0;
         /*min-width: 560px;*/
         min-width: calc(100% - 20px);
     }
 }
 .ranking-center{
     display: flex;
     align-items: center;
     justify-content: center;
 }
</style>
